<template>
    <div class="min-vh-100 p-3">

        <!-- headers, p, body -->
        <b-row>
            <b-col>
                <h1>Header h1</h1>
                <p>Nekoč je živela ljubka deklica in vsi, ki so jo poznali, so jo imeli radi. Njena babica, ki je imela
                    zanjo še prav poseben prostor v srcu, ji je podarila čudovito rdečo kapico. Tako navdušena je bila
                    deklica nad kapico, da jo je nosila, kamorkoli je šla, zato so jo vsi klicali Rdeča kapica.
                    Nekega dne jo je mama poklicala k sebi in rekla: "Rdeča kapica, vzemi tole košarico in nesi svoji
                    bolni babici nekaj hrane in pijače. Dobro ji bo delo. Pojdi skozi gozd, ne ustavljaj se in ne išči
                    bližnjic!" </p>
                <h2>Header h2</h2>
                <h3>Header h3</h3>
                <h4>Header h4</h4>
                <h5>Header h5</h5>
            </b-col>
            <b-col>
                <b-alert variant="primary" show>Primary Alert</b-alert>
                <b-alert variant="secondary" show>Secondary Alert</b-alert>
                <b-alert variant="success" show>Success Alert</b-alert>
                <b-alert variant="danger" show>Danger Alert</b-alert>
                <b-alert variant="info" show>Info Alert</b-alert>
                <b-alert variant="warning" show>Warning Alert</b-alert>
                <b-alert variant="light" show>Light Alert</b-alert>
                <b-alert variant="dark" show>Dark Alert</b-alert>
            </b-col>
            <b-col>
                <div style="height: 50px" class="mb-3 p-2 bg-primary">Primary background</div>
                <div style="height: 50px" class="mb-3 p-2 bg-secondary">Secondary background</div>
                <div style="height: 50px" class="mb-3 p-2 bg-success">Success background</div>
                <div style="height: 50px" class="mb-3 p-2 bg-danger text-white">Danger background</div>
                <div style="height: 50px" class="mb-3 p-2 bg-info">Info background</div>
                <div style="height: 50px" class="mb-3 p-2 bg-warning text-white">Warning background</div>
                <div style="height: 50px" class="mb-3 p-2 bg-light">Light background</div>
                <div style="height: 50px" class="mb-3 p-2 bg-dark text-white">Dark background</div>
            </b-col>
            <b-col>
                <div style="height: 50px" class="mb-3 p-2 bg-primary-light">Light Primary background</div>
                <div style="height: 50px" class="mb-3 p-2 bg-secondary-light">Light Secondary background</div>
                <div style="height: 50px" class="mb-3 p-2 bg-success-light">Light Success background</div>
                <div style="height: 50px" class="mb-3 p-2 bg-danger-light text-white">Light Danger background</div>
                <div style="height: 50px" class="mb-3 p-2 bg-info-light">Light Info background</div>
                <div style="height: 50px" class="mb-3 p-2 bg-warning-light text-white">Light Warning background</div>
                <div style="height: 50px" class="mb-3 p-2 bg-light-light">Light Light background</div>
                <div style="height: 50px" class="mb-3 p-2 bg-dark-light text-white">Light Dark background</div>
            </b-col>
        </b-row>

        <b-row style="height: auto">
            <!-- card -->
            <b-col>
                <b-card
                    header="Card header"
                    header-bg-variant="secondary"
                    header-text-variant="primary"
                    title="Card title"
                    sub-title="Subtitle"
                    class="text-left shadow"
                    header-class="font-weight-bold"
                    footer-bg-variant="primary"
                    footer-class="text-right"
                >
                    Card with something inside, like Little Red Riding Hood. ;)
                    <template #footer>
                        <b-row>
                            <b-col cols="auto" class="mr-auto">
                                <b-button variant="info">Info</b-button>
                            </b-col>
                            <b-col cols="auto" class="mr-2">
                                <b-button variant="warning">Cancel</b-button>
                            </b-col>
                            <b-col cols="auto" class="ml-0">
                                <b-button variant="danger">OK</b-button>
                            </b-col>
                        </b-row>
                    </template>
                </b-card>
            </b-col>
            <!-- sub-menu-sm -->
            <b-col>
                <sub-menu-main-sm></sub-menu-main-sm>
            </b-col>

            <!-- accordion -->
            <b-col>
                <sider-menu-accordion_deprecated></sider-menu-accordion_deprecated>
            </b-col>
        </b-row>

        <!-- table -->
        <b-row>
            <b-col>
                <organisation></organisation>
            </b-col>
        </b-row>

    </div>
</template>

<script>
import SubMenuMainSm from "@/components/general/layout/menu/main/SubMenuMainSm";
import Organisation from "@/components/manager/organisation/Organisation";
import SiderMenuAccordion_deprecated from "@/components/general/elements/menus/SiderMenuAccordion_deprecated";

// @group DEVELOPER
export default {
    name: "ThemePreview",
    components: {SiderMenuAccordion_deprecated, Organisation, SubMenuMainSm},
    props: {},
    data() {
        return {}
    },
    mounted() {
    },
    methods: {},
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
