<template>
    <div class="pt-3 mb-3">
        <div role="tablist">

            <!-- spletna osmica -->
            <b-card class="accordion-card" no-body style="margin: 0!important">
                <!-- header -->
                <b-card-header class="accordion-header-action" role="tab">
                    <b-button v-b-toggle.accordion-1 block
                              class="d-flex justify-content-between align-items-center"
                              href="#"
                              variant="info"
                    >
                        <h5 class="mb-0">Spletna osmica</h5>
                        <b-badge pill variant="light">20</b-badge>
                    </b-button>
                </b-card-header>
                <!-- sub_a -->
                <b-collapse id="accordion-1" accordion="sider-menu-accordion" role="tabpanel" visible>
                    <b-card-body class="p-1">
                        <b-list-group>
                            <b-list-group-item class="d-flex justify-content-between align-items-center"
                                               href="#"
                                               style="padding: 0.50rem 1.25rem"
                                               variant="secondary">
                                Artičoke
                                <b-badge pill variant="danger">8</b-badge>
                            </b-list-group-item>
                            <b-list-group-item class="d-flex justify-content-between align-items-center"
                                               href="#"
                                               style="padding: 0.50rem 1.25rem"
                                               variant="secondary">
                                Darila
                                <b-badge pill variant="danger">14</b-badge>
                            </b-list-group-item>

                            <b-list-group-item class="d-flex justify-content-between align-items-center"
                                               href="#"
                                               style="padding: 0.50rem 1.25rem"
                                               variant="secondary">
                                Olivno olje
                                <b-badge pill variant="danger">2</b-badge>
                            </b-list-group-item>
                        </b-list-group>
                    </b-card-body>
                </b-collapse>
            </b-card>

            <!-- Zelenjava -->
            <b-card class="accordion-card" no-body>
                <!-- header -->
                <b-card-header class="accordion-header" role="tab">
                    <b-button v-b-toggle.accordion-2 block
                              class="d-flex justify-content-between align-items-center"
                              href="#"
                              variant="primary"
                    >
                        <h5 class="mb-0">Zelenjava</h5>
                        <b-badge pill variant="secondary">48</b-badge>
                    </b-button>
                </b-card-header>
                <!-- sub -->
                <b-collapse id="accordion-2" accordion="sider-menu-accordion" role="tabpanel">
                    <b-card-body style="padding: 0;">
                        <b-list-group>
                            <b-list-group-item class="d-flex justify-content-between align-items-center"
                                               href="#"
                                               style="padding: 0.50rem 1.25rem"
                                               variant="success">
                                Krompir
                                <b-badge pill variant="primary">8</b-badge>
                            </b-list-group-item>
                            <b-list-group-item class="d-flex justify-content-between align-items-center"
                                               href="#"
                                               style="padding: 0.50rem 1.25rem"
                                               variant="success">
                                Bučke
                                <b-badge pill variant="primary">13</b-badge>
                            </b-list-group-item>

                            <b-list-group-item class="d-flex justify-content-between align-items-center"
                                               href="#"
                                               style="padding: 0.50rem 1.25rem"
                                               variant="success">
                                Paradižnik
                                <b-badge pill variant="primary">4</b-badge>
                            </b-list-group-item>

                            <b-list-group-item ariant="success" class="d-flex justify-content-between align-items-center"
                                               href="#"
                                               style="padding: 0.50rem 1.25rem"
                                               v>
                                Kumarice
                                <b-badge pill variant="primary">3</b-badge>
                            </b-list-group-item>

                            <b-list-group-item class="d-flex justify-content-between align-items-center"
                                               href="#"
                                               style="padding: 0.50rem 1.25rem"
                                               variant="success">
                                Čokolada
                                <b-badge pill variant="primary">2</b-badge>
                            </b-list-group-item>

                            <b-list-group-item class="d-flex justify-content-between align-items-center"
                                               href="#"
                                               style="padding: 0.50rem 1.25rem"
                                               variant="success">
                                Solata
                                <b-badge pill variant="primary">12</b-badge>
                            </b-list-group-item>

                            <b-list-group-item class="d-flex justify-content-between align-items-center"
                                               href="#"
                                               style="padding: 0.50rem 1.25rem"
                                               variant="success">
                                Paradižnik
                                <b-badge pill variant="primary">6</b-badge>
                            </b-list-group-item>
                        </b-list-group>
                    </b-card-body>
                </b-collapse>
            </b-card>


            <!-- Items -->
            <!--            <div v-for="item in items">-->
            <!--                <b-card no-body class="accordion-card">-->
            <!--                    <b-card-header class="accordion-header" role="tab">-->
            <!--                        <b-button block href="#"-->
            <!--                                  class="d-flex justify-content-between align-items-center"-->
            <!--                                  v-b-toggle.accordion-3-->
            <!--                                  :variant="item.variant"-->
            <!--                        >-->
            <!--                            <h5 class="mb-0">{{item.title}}</h5>-->
            <!--                            <b-badge variant="success" pill>{{item.count}}</b-badge>-->
            <!--                        </b-button>-->
            <!--                    </b-card-header>-->
            <!--                    &lt;!&ndash; items1 &ndash;&gt;-->
            <!--                    <b-collapse id="accordion-3" accordion="sider-menu-accordion" role="tabpanel">-->
            <!--                        <div v-for="item1 in item.items1">-->
            <!--                            <b-card-body style="padding: 0;">-->
            <!--                                <b-list-group>-->
            <!--                                    <b-list-group-item-->
            <!--                                        href="#"-->
            <!--                                        :variant="item1.variant !== undefined ? item1.variant : defaultVariant"-->
            <!--                                        style="padding: 0.50rem 1.25rem"-->
            <!--                                        class="d-flex justify-content-between align-items-center"-->
            <!--                                    >-->
            <!--                                        {{item1.title}}-->
            <!--                                        <b-badge variant="primary" pill>{{item1.count}}</b-badge>-->
            <!--                                    </b-list-group-item>-->
            <!--                                </b-list-group>-->
            <!--                            </b-card-body>-->
            <!--                        </div>-->
            <!--                    </b-collapse>-->
            <!--                </b-card>-->
            <!--            </div>-->
        </div>
    </div>
</template>

<script>
export default {
    name: "SiderMenuAccordion_deprecated",
    components: {},
    props: {
        defaultVariant: {
            type: String,
            default() {
                return 'danger'
            }
        },
        selectVariant: {
            type: String,
            default() {
                return 'primary'
            }
        }
    },
    data() {
        return {
            items: [
                {
                    title: 'Kmečka kuhna',
                    count: 100,
                    variant: 'info',
                    items1: [
                        {
                            title: 'Špageti',
                            count: 100,
                        },
                        {
                            title: 'Široki bučni rezanci',
                            count: 99,
                        },
                        {
                            title: 'Ozki rezanci',
                            count: 358,
                            variant: this.selectVariant,
                        },
                        {
                            title: 'Jušni rezanci',
                            count: 55,
                        },
                    ]
                },
                {
                    title: 'Sadike in semena',
                    count: 120,
                    variant: 'primary',
                    items1: {}
                },
            ]
        }
    },
    mounted() {
    },
    methods: {},
    computed: {},
    filters: {},
    watch: {}
}


</script>

<style>

</style>
